/*/
 * 
 * JSON Editor overrides
 * 
\*/

.jsoneditor {
  border: none;
}

// Necessary to prevent a container with "overflow:hidden" from cropping context menus
// You may be required to increment this value if adding menu elements
// Menu is a dropdown until arount line 14 of the editor and is at it's biggest for objects
.jsoneditor-mode-tree {
  min-height: 780px;
}

// fix ACE editor current line highlight hiding the jsoneditor mode menu
.jsoneditor-contextmenu {
  z-index: 2;
}

// hide "powered by ace" text
.jsoneditor-poweredBy {
  display: none;
}

.jsoneditor-menu {
  background-color: $main-color;
  border-bottom: none;
}

.jsoneditor-modal {
  a, .jsoneditor-jmespath-label {
    color: $main-color;
  }

  .pico-modal-header {
    background-color: $main-color;
  }

  .jsoneditor-button-group {
    &.jsoneditor-button-group-value-asc input.jsoneditor-button-asc,
    &.jsoneditor-button-group-value-desc input.jsoneditor-button-desc {
      background-color: $main-color;
      border-bottom: $main-color;
    }
  }
}

.selectr-option.active {
  background-color: $main-color;
}

.jsoneditor-contextmenu .jsoneditor-icon {
  top: 2px;
}

.jsoneditor-contextmenu .jsoneditor-menu li button {
  line-height: 1.5;

  &.jsoneditor-selected {
    &, &:hover, &:focus {
      background-color: $main-color;
    }
  }
}

div.jsoneditor-tree div.jsoneditor-show-more a {
  &:hover, &:focus {
    color: $main-color;
  }
}

.ace_editor {
  min-height: 450px;
  line-height: 1.5;
}
