$size: 150px;

.petzi-login {
  background-position: center center;

  &:before {
    content: '';
    box-sizing: border-box;
    display: block;
    width: $size;
    height: $size;
    position: absolute;
    top: 20px;
    right: 20px;
    background-image: url('/petzi-logo.svg');
    background-repeat: no-repeat;
    background-color: white;
    border-radius: $size/2;
    background-size: $size*.7;
    background-position: center $size/5.5;
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }  
}
