// SurveyCreator
#surveyEditor {
  margin-left: -24px;
  margin-right: -24px;
}
.svd-simulator-content {
  padding: 0 24px;
  background-color: $light;
}
.svd-simulator-main {
  margin-left: -30px;
  margin-right: -30px;
}
.svd_container {
  background-color: $light !important;
  .svd-light-bg-color{
    //background-color: $medium !important;
    background-color: $light !important;
  }
  .svd-dark-bg-color{
    background-color: $dark !important;
  }
  .svd_editors {
    .sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
      margin-left: 0;
      margin-right: 0;
      padding-left: 14px;
      padding-right: 14px;
    }
    .sv_main .sv_container .sv_body .sv_p_root > .sv_row {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .question_actions.svd_question {
        border: 1px solid transparent;
    }
  }
  pages-editor .svd-page {
    //background-color: #e0e0e0;
  }
  .svd_content{
    padding-bottom: 0;
    background-color: $dark !important;
    .svd_survey_designer {
      margin-left: -30px;
      margin-right: -30px;
      width: auto;
    }
    .description_editable {
      padding-left: 14px;
      padding-right: 14px;
    }
  }
}
.svd_container.sv_default_css{
  #surveyjs .sv_row .svd_question {
    padding: 14px;
    padding-top: 14px;
  }
}

// SurveyJS

.sv_main {
  margin-top: 24px;
  //background-color: #f5f5f5;
  .sv_custom_header {
    display: none;
  }
  &.sv_default_css .sv_header {
    box-sizing: content-box;
    margin-left: -24px;
    margin-right: -24px;
    padding: 2em 24px;
    margin-bottom: 3em;
    h5 {
      font-size: inherit;
      margin-bottom: 0;
      line-height: 1.5em;
    }
  }
  .sv_container {
    background-color: $light;
    .sv_body {
      padding: 0;
      border-top: none;
      background-color: $light !important;
      button, input[type=button] {
        // MUI styles to match react-admin
        box-sizing: border-box;
        transition:
          background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-weight: 500;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        line-height: 1.4em;
        border-radius: 4px;
        text-transform: uppercase;
        padding: 7px 8px;
        min-width: 64px;
        font-size: 0.8125rem;
        min-height: 32px;
        outline:none;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      }
      .sv_p_root {
        margin-bottom: 3em;
        .sv_q {
          padding: 1.5em 0 2.5em 0
        }
        .sv_q_description {
          margin-top: 0;
          margin-bottom: 1em;
          line-height: 1.5em;
          text-align: justify;
        }
        .sv_p_title {
          padding-left: 0;
          padding-right: 0;
        }
        .sv_page_title {
          box-sizing: content-box;
          padding-left: 24px;
          padding-right: 24px;
          margin-left: -24px;
          margin-right: -24px;
          font-size: 1.4em;
          margin-bottom: 1em;
          color: $main-color;
          font-weight: bold;
        }
        & > .sv_row {
          box-sizing: content-box;
          padding-left: 24px;
          padding-right: 24px;
          margin-left: -24px;
          margin-right: -24px;
        }
      }
      .sv_progress {
        height: .7em;
        border-radius: .7em;
        text-align: center;
        margin-bottom: 0;
        pointer-events: none;
        .sv_progress_bar {
          border-radius: .7em;
        }
        span {
          margin-top: 14px;
          background-color: rgb(231, 231, 231);
          padding: 8px 16px;
          min-height: 36px;
          border-radius: 2em;
          font-size: 0.875rem;
          line-height: 1.4em;
          font-weight: 500;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          box-sizing: border-box;
          display: inline-block;
        }
      }
      .sv_nav {
        margin: 0 0 2.5em 0;
        .sv_next_btn, .sv_prev_btn, .sv_complete_btn  {
          // Reset
          margin-right: 0 !important;
          // MUI style
          padding: 8px 16px;
          min-height: 36px;
          background-color: transparent;
          color: $main-color;
          border: 1px solid transparentize($main-color, .5);
          box-shadow: none;
        }
        .sv_next_btn:hover, .sv_prev_btn:hover, .sv_complete_btn:hover {
          border: 1px solid $main-color;
          background-color: transparentize($main-color, .8);
        }
      }
    }
  }
}
